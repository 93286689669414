<div ngbDropdown class="language-selector nav-item dropdown" display="dynamic" *transloco="let t; read: 'language'">
  <button class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdownUser" aria-expanded="false">
    <span *ngIf="isMobile$ | async" class="me-1">
      {{ t('title') }}
    </span>
    <i class="bi bi-fs-1_2x fw-bold bi-translate"></i>
  </button>
  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdownUser">
    <button *ngFor="let language of languages" ngbDropdownItem (click)="switchLanguage(language.code)"
      [ngClass]="{'active':isActive(language.code)}">
      <img [src]="language.icon" [alt]="language.code" class="language-icon">{{ language.title }}
    </button>
  </div>
</div>
